@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap");

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
    position: relative;
    width: 100%;
  }

  .product-title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    width: 300px;
    max-height: 100px;
  }

  @media (max-width: 480px) {
    .header-container {
      justify-content: center;
      text-align: center;
    }

    .top-social-icons {
      width: auto;
      justify-content: flex-end;
      padding-top: 40px;
    }
  }

  .top-social-icons {
    position: absolute;
    top: 35px;
    right: 40px;
    display: flex;
    gap: 25px;
    align-items: center;

    .blog-button {
      padding: 8px 16px;
      font-size: 1.1rem;
      font-weight: 600;
      color: black;
      background-color: white;
      border: 2px solid black;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-family: "Montserrat", sans-serif;
      margin-right: 10px;

      &:hover {
        background-color: #f8f8f8;
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0);
      }
    }

    a {
      color: #000;
      font-size: 1.8rem;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);

        &.youtube {
          color: #ff0000;
        }
        &.twitter {
          color: #1da1f2;
        }
        &.reddit {
          color: #ff4500;
        }
        &.discord {
          color: #5865f2;
        }
      }
    }
  }

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 2em;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;

      .headline {
        font-size: 2.5rem;
        font-weight: bold;
        color: #000;
        margin: 2em 0 0;

        @media (max-width: 768px) {
          font-size: 2rem !important;
        }

        @media (max-width: 600px) {
          font-size: 1.75rem !important;
        }

        @media (max-width: 480px) {
          font-size: 1.5rem !important;
        }
      }

      .paragraph {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #555;
        font-weight: 600;

        &:last-of-type {
          color: black;
        }

        .keyword {
          font-size: 1.2rem;
          font-family: "Playfair Display", serif;
          font-style: italic;
          font-weight: 500;
          color: black;
          position: relative;
          display: inline-block;
          padding: 0 4px;
          vertical-align: middle;
          margin: 0 2px;
        }
      }

      .button-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .start {
        background-color: #000;
        color: #fff;
        padding: 15px 30px;
        border: 1px solid black;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1.1rem;
        font-weight: 600;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #555;
        }
      }
    }

    .player-container {
      width: 80%;
      max-width: 700px;
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }

    .cta-section {
      width: 100%;
      max-width: 600px;
      margin: 0em auto;
      text-align: center;

      .get-started-button {
        padding: 16px 32px;
        font-size: 1.1rem;
        font-weight: 600;
        color: white;
        background-color: #000;
        border: 2px solid #000;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-family: "Montserrat", sans-serif;

        &:hover {
          background-color: #333;
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0);
        }
      }
    }

    .contact-section {
      width: 100%;
      max-width: 600px;
      margin: 2em auto 12em;
      text-align: center;

      .paragraph {
        font-size: 1.2rem;
        line-height: 1.5;
        color: black;
        font-weight: 600;

        .email-link {
          color: #000;
          text-decoration: none;
          border-bottom: 1px solid #000;
          padding-bottom: 1px;
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .waitlist-form {
        flex-direction: column;
        padding: 15px !important;

        .waitlist-input,
        .waitlist-button {
          width: 100%;
          max-width: 100% !important;
        }
      }

      .disclaimer-text {
        padding: 0;
        font-size: 0.75rem;
        width: 100%;
      }
    }

    .audio-examples-section {
      width: 100%;
      max-width: 900px;
      margin-top: 3em;

      @media (max-width: 768px) {
        margin-top: 2.5em !important;
      }

      @media (max-width: 600px) {
        margin-top: 2em !important;
      }

      @media (max-width: 480px) {
        margin-top: 1.5em !important;
      }

      .paragraph {
        font-size: 1.2rem;
        line-height: 1.5;
        color: black;
        font-weight: 600;
        margin-bottom: 2em;
      }

      .player-container {
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1em auto;
      }

      .player-container:first-of-type {
        margin-top: 0;
      }

      .player-container:not(:last-child) {
        border-bottom: 1px solid #eee;
        padding-bottom: 1em;
      }

      .player-container:last-child {
        margin-bottom: 6em;
      }
    }
  }

  // Add these new styles for the panel sections
  .sync-container {
    width: 90%;
    max-width: 1200px;
    height: 500px;
    margin: 2em auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
  }

  [data-panel-group-direction="horizontal"] {
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
  }

  [data-panel-resize-handle-id] {
    width: 4px;
    background-color: #ddd;
    cursor: col-resize;
  }

  .text-editing {
    padding: 0;
    line-height: 1.6;
    height: 100%;
    overflow-y: auto;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    text-align: left;
    position: relative;

    .screenplay-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      padding-top: 20px;
      padding-bottom: 12px;
      color: #333;
      text-align: center;
      margin-bottom: 20px;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .screenplay {
      scroll-behavior: smooth;
      padding: 20px;
      line-height: 1.6;
      font-size: 0.9rem;
      text-align: left;
      height: calc(100% - 70px);
      overflow-y: auto;

      .dialogue-item {
        margin-bottom: 15px;
        padding: 10px;
        border-radius: 4px;
        border: 1px solid #ddd;
        text-align: left;

        .character {
          font-weight: bold;
          margin-right: 5px;
          text-align: left;
        }

        .split-sentence {
          display: inline;
          cursor: pointer !important;
          padding: 2px;
          margin: 0;
          border-radius: 2px;
          transition: all 0.2s ease;
          position: relative;
          text-align: left;

          &.clickable {
            &:hover {
              background-color: rgba(0, 0, 0, 0.05) !important;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
            }
          }

          &.current-sentence {
            background-color: #cdcccc !important;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
            position: relative;
          }
        }
      }
    }
  }

  .multitrack-container {
    height: 100%;
    overflow-y: auto;
    background-color: white;
    display: flex;
    flex-direction: column;

    .multitrack-title {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 10;
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      padding: 24px 0 13px;
      color: #333;
      text-align: center;
      border-bottom: 2px solid #eee;
      width: 100%;
    }

    .multitrack-wrapper {
      padding-top: 20px;
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      gap: 15px;
      position: relative;
      margin-top: 55px;
      // overflow-x: hidden;

      .multitrack-controls {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -45px;
        z-index: 1;

        .play-pause-button {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #000;
          color: white;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.2s ease;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          margin-top: -2px;

          &:hover {
            transform: translateY(-2px);
            background-color: #333;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
          }

          &:active {
            transform: translateY(1px);
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
          }

          svg {
            font-size: 1.1rem;
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
          }
        }
      }

      .track-labels {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        min-width: 60px;
        padding: 0px;
        gap: 0px;
        font-size: 0px;
        line-height: 0px;

        .audio-label {
          font-weight: bold;
          font-size: 0.8rem;
          text-align: center;
          height: 58px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0px;
          margin-bottom: -1px;
          white-space: pre;
          word-spacing: normal;
        }
      }

      div[ref="multitrackContainerRef"] {
        height: 400px !important;
        // Make sure this container can grow if needed
        min-height: 400px;
        height: fit-content !important;
      }
    }
  }

  .multitrack-text-container {
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box; // Add this

    .multitrack-text-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      padding: 20px;
      color: #333;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 15px;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .multitrack-text-content {
      flex: 1;
      margin-top: 20px;
      padding: 20px;
      background-color: white;
      border-radius: 8px;
      border: 1px solid #eee;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
      overflow-y: auto;
      white-space: pre-wrap;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 0.9rem;
      line-height: 1.6;
      color: #333;
      text-align: left;
    }
  }

  .text-input-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .text-input-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      padding: 15px 0;
      color: #333;
      text-align: center;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
    }

    .text-input-container {
      flex: 1;
      padding: 20px;
      padding-top: 85px;
      padding-bottom: 15px;
      overflow-y: auto;
      position: relative;
      width: 100%;
      box-sizing: border-box;

      .text-input-area {
        width: 100%;
        height: calc(100% - 60px);
        padding: 15px;
        border: 2px solid #eee;
        border-radius: 8px;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 0.9rem;
        line-height: 1.6;
        resize: none;
        transition: all 0.3s ease;
        overflow-x: hidden;
        box-sizing: border-box;
        word-wrap: break-word;
        white-space: pre-wrap;
        color: #333;

        &:focus {
          outline: none;
          border-color: #000;
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
        }

        &::placeholder {
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          font-size: 0.9rem;
          color: #aaa;
        }
      }

      .word-count {
        position: absolute;
        bottom: 25px;
        right: 32px;
        font-size: 0.9rem;
        color: #666;
        padding: 4px 8px;
        border-radius: 4px;
        background: none;
        font-weight: 500;
        opacity: 0.8;
      }
    }
  }

  .compare-message {
    text-align: center;
    margin: 40px 0 30px;
    font-family: "Playfair Display", serif;
    font-style: italic;

    span {
      display: inline-block;
      margin: 0 6px;
      animation: float 3s ease-in-out infinite;
    }

    .transform {
      font-size: 2.6rem;
      background: linear-gradient(45deg, #000, #222);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.1s;
    }

    .text {
      font-size: 2.2rem;
      color: #222;
      font-weight: 500;
      animation-delay: 0.2s;
    }

    .into {
      font-size: 2.3rem;
      color: #222;
      font-weight: 500;
      animation-delay: 0.3s;
    }

    .premium {
      font-size: 2.2rem;
      color: #333;
      animation-delay: 0.4s;
      margin: 0 10px;
    }

    .audiobook {
      font-size: 2.3rem;
      color: #333;
      font-weight: 500;
      animation-delay: 0.5s;
    }

    .faster {
      font-size: 2.6rem;
      background: linear-gradient(45deg, #000, #222);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.6s;
    }

    .faster-text {
      font-size: 2.2rem;
      color: #222;
      font-weight: 500;
      animation-delay: 0.5s;
    }

    .better {
      font-size: 2.6rem;
      background: linear-gradient(45deg, #000, #222);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.6s;
    }

    @keyframes float {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }

  .tap-message {
    text-align: center;
    margin: 30px 0;
    font-family: "Playfair Display", serif;
    font-style: italic;

    span {
      display: inline-block;
      margin: 0 8px;
      animation: float 3s ease-in-out infinite;
    }

    .tap {
      font-size: 2.5rem;
      color: #333;
      font-weight: 600;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
    }

    .and {
      font-size: 2.8rem;
      color: #000;
      font-weight: 700;
      animation-delay: 0.4s;
      margin: 0 15px;
    }

    .enjoy {
      font-size: 3rem;
      background: linear-gradient(45deg, #000, #333);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 800;
      animation-delay: 0.6s;
    }

    @keyframes float {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }

  .demo-message {
    text-align: center;
    margin: 40px 0 30px;
    font-family: "Playfair Display", serif;
    font-style: italic;

    span {
      display: inline-block;
      margin: 0 6px;
      animation: float 3s ease-in-out infinite;
    }

    .take {
      font-size: 2.2rem;
      color: #333;
      font-weight: 600;
    }

    .look {
      font-size: 2rem;
      color: #444;
      font-weight: 500;
      animation-delay: 0.1s;
    }

    .at {
      font-size: 1.8rem;
      color: #555;
      animation-delay: 0.2s;
    }

    .highlight {
      font-size: 2.4rem;
      background: linear-gradient(45deg, #000, #333);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.3s;
    }

    .and {
      font-size: 2rem;
      color: #444;
      font-weight: 600;
      animation-delay: 0.4s;
      margin: 0 10px;
    }

    .listen {
      font-size: 2.2rem;
      color: #333;
      font-weight: 600;
      animation-delay: 0.5s;
    }

    .yourself {
      font-size: 2rem;
      background: linear-gradient(45deg, #333, #000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.6s;
    }
  }

  .demo-subtitle {
    text-align: center;
    margin: -5px 0 35px;
    font-family: "Playfair Display", serif;
    font-style: italic;

    span {
      display: inline-block;
      margin: 0 6px;
      animation: float 3s ease-in-out infinite;
    }

    .interested {
      font-size: 1.3rem;
      color: #333;
      font-weight: 500;
      animation-delay: 0.1s;
    }

    .customize {
      font-size: 1.3rem;
      color: #333;
      font-weight: 600;
      animation-delay: 0.2s;
    }

    .scroll {
      font-size: 1.3rem;
      background: linear-gradient(45deg, #000, #333);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.3s;
    }

    .and {
      font-size: 1.2rem;
      color: #555;
      animation-delay: 0.4s;
    }

    .start {
      font-size: 1.3rem;
      background: linear-gradient(45deg, #333, #000);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      animation-delay: 0.5s;
    }

    @keyframes float {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-5px);
      }
    }

    @media (max-width: 768px) {
      span {
        font-size: 1.1rem;
        margin: 0 4px;
      }
    }
  }

  .social-proof {
    width: 100%;
    max-width: 1200px;
    margin: 3em auto;
    padding: 2em 0;
    background: linear-gradient(
      to right,
      rgba(248, 249, 250, 0.5),
      rgba(255, 255, 255, 0.8),
      rgba(248, 249, 250, 0.5)
    );
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);

    .social-proof-title {
      font-size: 1.1rem;
      font-weight: 500;
      color: #666;
      text-align: center;
      margin-bottom: 2em;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }

    .partner-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 100px;
      padding: 0 4em;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;

      .partner-item {
        display: flex;
        align-items: center;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        .partner-logo {
          height: 80px;
          object-fit: contain;
          transition: all 0.3s ease;

          &.stanford {
            height: 90px;
          }

          &.microsoft {
            height: 70px;
          }
        }

        .partner-name {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      margin: 2em auto;
      padding: 1.5em 0;

      .partner-logos {
        gap: 2em;
        padding: 0 2em;

        .partner-item {
          .partner-logo {
            height: 70px;

            &.stanford {
              height: 80px;
            }

            &.microsoft {
              height: 60px;
            }
          }
        }
      }
    }
  }

  .feature-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;

    .feature-section {
      background-color: white;
      width: 80%;
      margin: 1rem auto;
      display: flex;
      align-items: center;

      margin-bottom: 50px;
      margin-left: 50px;
      margin-right: 50px;

      border: 2px solid black;

      &:hover {
        border: 2px solid black;
        transform: scale(1.05);
        transition: all 0.3s ease;
      }
    }

    .feature-content {
      width: 50%;
      padding: 1em;
    }

    .feature-title {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: black;
    }

    .feature-description {
      font-size: 1.1rem;
      line-height: 1.3;
      color: black;
      padding-bottom: 20px;
    }

    // Add media query for mobile responsiveness
    @media (max-width: 768px) {
      .feature-section {
        flex-direction: column;
        padding: 1rem;
        width: 80%;
      }

      .feature-content,
      .feature-image {
        width: 90%;
      }

      .feature-content {
        margin-bottom: 2rem;
      }
    }
  }

  .feature-comparison-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;

    .feature-title-right {
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }

    .feature-comparison {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }

    @media (max-width: 768px) {
      .feature-comparison {
        width: 180%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .toggle-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .toggle-wrapper {
      background-color: #f8f9fa;
      padding: 6px;
      border-radius: 12px;
      display: flex;
      gap: 6px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
      border: 1px solid #eee;
    }

    .toggle-button {
      padding: 14px 28px;
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      background: transparent;
      color: #666;
      transition: all 0.3s ease;
      font-family: "Montserrat", sans-serif;

      &:hover {
        color: #333;
        background-color: rgba(0, 0, 0, 0.03);
      }

      &.active {
        background-color: #000;
        color: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .instruction-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1200px;
    margin: 2em auto;
    padding: 20px;
    gap: 15px;

    .instruction-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 15px 10px;
      position: relative;

      .step-number {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #000;
        color: white;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        font-weight: bold;
      }

      .instruction-icon {
        font-size: 2rem;
        color: #333;
        margin: 15px 0;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.1);
        }
      }

      .instruction-text {
        font-size: 0.85rem;
        line-height: 1.4;
        color: #555;
        margin: 0;
        font-weight: 500;
      }

      &:not(:last-child)::after {
        content: "→";
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        color: #999;
        font-size: 1.2rem;
      }
    }
  }

  @media (max-width: 1200px) {
    .instruction-section {
      flex-wrap: wrap;
      gap: 30px;

      .instruction-item {
        flex: 1 1 calc(25% - 30px);
        min-width: 200px;

        &:not(:last-child)::after {
          display: none;
        }
      }
    }
  }

  .company-footer {
    width: 90%;
    max-width: 1200px;
    padding-bottom: 40px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-left {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;

      .company-info {
        display: flex;
        align-items: center;
        gap: 10px;

        .company-logo {
          width: 160px;
          max-height: 50px;
          object-fit: contain;
        }

        .company-name {
          font-size: 1.2rem;
          font-weight: 600;
          color: #000;
        }
      }

      .copyright {
        font-size: 0.9rem;
        color: #666;
        font-weight: 500;
      }

      .company-email {
        font-size: 0.95rem;
        color: #000;
        text-decoration: none;
        font-weight: 600;
        padding-bottom: 2px;
        border-bottom: 1px solid #000;
        transition: all 0.2s ease;
        letter-spacing: 0.2px;

        &:hover {
          opacity: 1;
          color: #666;
          border-bottom-color: #666;
        }
      }
    }

    .social-icons {
      position: static;
      display: flex;
      gap: 10px;
      margin-left: auto;
      padding: 0;

      a {
        color: #000;
        font-size: 2rem;
        transition: color 0.3s ease;

        &:hover {
          &.twitter {
            color: #1da1f2;
          }
          &.reddit {
            color: #ff4500;
          }
        }
      }
    }

    // Add responsive padding for smaller screens
    @media (max-width: 768px) {
      width: 90%;
      padding: 40px 0;
      flex-direction: column;
      gap: 30px;

      .social-icons {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-page {
    .center-container {
      .content-wrapper {
        .headline {
          font-size: 2rem;
        }

        .paragraph {
          font-size: 1rem;
        }
      }
    }
  }
}

// Update button styles to handle loading state
.transform-button,
.voice-assignment-button,
.generate-voice-button {
  &.loading {
    cursor: not-allowed;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .button-text {
      margin-left: 8px;
    }

    .button-arrow,
    .transform-arrow {
      display: none;
    }
  }
}
