@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500&display=swap");

.alpha-demo-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  .product-title {
    position: absolute;
    top: 20px;
    left: 30px;
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    width: 300px;
    max-height: 100px;
  }

  // Add these new styles for the panel sections
  .sync-container {
    width: 90%;
    max-width: 1200px;
    height: 500px;
    margin: 2em auto;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: white;
    overflow: hidden;
  }

  [data-panel-group-direction="horizontal"] {
    height: 100%;
    background-color: white;
    margin: 0;
    padding: 0;
  }

  [data-panel-resize-handle-id] {
    width: 4px;
    background-color: #ddd;
    cursor: col-resize;
  }

  .text-input-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .text-input-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 15px 20px;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;

      .text-input-title {
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
        font-weight: 600;
        color: #333;
        width: 100%;
        text-align: center;
      }

      .transform-button {
        padding: 12px 24px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        background-color: #000;
        color: white;
        transition: all 0.3s ease;
        font-family: "Montserrat", sans-serif;
        display: flex;
        align-items: center;
        gap: 8px;

        .transform-icon {
          font-size: 1.1rem;
        }

        .transform-text {
          margin: 0;
        }

        .transform-arrow {
          font-size: 1.1rem;
          transition: transform 0.3s ease;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #999;
          transform: none !important;

          &:hover {
            transform: none !important;
            box-shadow: none !important;
          }
        }

        &.loading {
          cursor: not-allowed;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;

          .button-text {
            margin-left: 8px;
          }

          .transform-arrow {
            display: none;
          }
        }
      }
    }

    .text-input-container {
      flex: 1;
      padding: 20px;
      overflow-y: auto;
      position: relative;
      width: 100%;
      box-sizing: border-box;

      .text-input-area {
        width: 100%;
        height: calc(100% - 60px);
        padding: 15px;
        border: 2px solid #eee;
        border-radius: 8px;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 0.9rem;
        line-height: 1.6;
        resize: none;
        transition: all 0.3s ease;
        overflow-x: hidden;
        box-sizing: border-box;
        word-wrap: break-word;
        white-space: pre-wrap;
        color: #333;

        &:focus {
          outline: none;
          border-color: #000;
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
        }

        &::placeholder {
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          font-size: 0.9rem;
          color: #aaa;
        }
      }

      .word-count {
        position: absolute;
        bottom: 25px;
        right: 32px;
        font-size: 0.9rem;
        color: #666;
        padding: 4px 8px;
        border-radius: 4px;
        background: none;
        font-weight: 500;
        opacity: 0.8;
      }
    }

    // Add these specific styles for the music description view
    &.music-description {
      .text-input-header {
        .text-input-title {
          color: #2a4858; // Slightly different color for music section
        }
      }

      .text-input-area {
        &::placeholder {
          font-style: italic;
          color: #888;
        }
      }
    }
  }

  .voice-exhibit-section {
    position: relative;
    width: 90%;
    max-width: 1200px;
    margin: 2em auto;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 600px;
    overflow: hidden;

    .vertical-menu {
      position: absolute;
      left: 0;
      top: 0;
      width: 200px;
      height: 100%;
      border-right: 1px solid #eee;
      background-color: #fff;
      border-radius: 12px 0 0 12px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .menu-buttons {
        flex: 1;
        overflow-y: auto;
      }

      .remaining-quota {
        height: 30px;
        width: 100%;
        text-align: center;
        padding-top: 12px;
        border-top: 1px solid #eee;
        background-color: #fff;
        border-radius: 0 0 0 12px;
        flex-shrink: 0; // Prevent shrinking
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
      }

      .menu-item {
        width: 100%;
        padding: 15px 20px;
        border: none;
        background: none;
        cursor: pointer;
        transition: all 0.2s ease;
        text-align: left;
        color: #666;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 3px;
          background-color: #000;
          transform: scaleY(0);
          transition: transform 0.2s ease;
        }

        .menu-content {
          position: relative;
          display: flex;
          align-items: center;
          gap: 12px;
          padding-left: 36px;

          &:not(:has(.menu-step)) {
            padding-left: 12px;
          }

          .menu-step {
            position: absolute;
            left: 0;
            width: 24px;
            height: 24px;
            background-color: #000;
            color: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.9rem;
            font-weight: 600;
            transition: all 0.2s ease;
          }

          .menu-icon {
            font-size: 1.2rem;

            &:contains("🎵") {
              font-size: 1.3rem;
            }
          }

          .menu-text {
            font-size: 1rem;
            font-weight: 500;
          }
        }

        &:last-child .menu-content {
          padding-left: 12px;
        }

        &.active {
          background-color: rgba(0, 0, 0, 0.03);
          color: #000;
          font-weight: 600;

          &::before {
            transform: scaleY(1);
          }

          .menu-step {
            transform: scale(1.1);
            background-color: #000;
          }
        }

        &:hover:not(.active) {
          background-color: rgba(0, 0, 0, 0.02);

          .menu-step {
            transform: scale(1.05);
          }
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #f5f5f5;
          color: #999;

          .menu-step {
            background-color: #999;
          }

          &:hover {
            background-color: #f5f5f5;
            .menu-step {
              transform: none;
            }
          }

          // Add specific styles for multitrack audio button
          &[disabled]:has(.menu-text:contains("Multitrack Audio")) {
            background-color: #f5f5f5;
            color: #999;
          }
        }
      }
    }

    .voice-content {
      margin-left: 200px;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;

      .voice-list {
        flex: 1;
        overflow-y: auto;
        padding-bottom: 40px;
      }
    }

    .voice-exhibit-title {
      font-size: 1.5rem;
      margin-bottom: 20px;
      color: #333;
    }

    .voice-filters {
      margin-bottom: 20px;

      select {
        padding: 8px 12px;
        border-radius: 6px;
        border: 1px solid #ddd;
        font-size: 0.9rem;
        background-color: white;
        cursor: pointer;

        &:focus {
          outline: none;
          border-color: #2196f3;
        }
      }
    }

    .voice-list {
      max-height: 600px;
      overflow-y: auto;
      padding: 10px;

      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;

        &:hover {
          background: #666;
        }
      }
    }

    .voice-item {
      display: flex;
      align-items: center;
      padding: 15px;
      border-bottom: 1px solid #eee;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f8f9fa;
      }
    }

    .voice-avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: white;
      margin-right: 15px;
      flex-shrink: 0;
    }

    .voice-info {
      flex: 1;

      .voice-name {
        font-size: 1.1rem;
        margin: 0 0 5px 0;
        color: #333;
        font-weight: 600;
      }

      .voice-description {
        font-size: 0.9rem;
        color: #666;
        margin: 0 0 8px 0;
      }

      .voice-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .voice-category {
          background-color: #e3f2fd;
          color: #1976d2;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 0.8rem;
        }

        .voice-tag {
          background-color: #f5f5f5;
          color: #666;
          padding: 4px 8px;
          border-radius: 4px;
          font-size: 0.8rem;
        }
      }
    }

    .play-voice-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #000;
      padding: 12px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      margin-left: 10px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        transform: translateY(-2px);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      }

      svg {
        font-size: 24px;
        transition: all 0.2s ease;
      }

      &.playing svg {
        color: #000;
        filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
      }
    }

    .voice-loading {
      text-align: center;
      padding: 2em;
      color: #666;
      font-size: 1.1rem;
      font-style: italic;
    }

    .voice-search {
      margin-bottom: 20px;
      padding: 0 10px;

      .voice-search-input {
        width: 100%;
        max-width: 400px;
        padding: 12px 20px;
        font-size: 1rem;
        border: 2px solid #eee;
        border-radius: 8px;
        transition: all 0.3s ease;
        font-family: "Montserrat", sans-serif;

        &:focus {
          outline: none;
          border-color: #000;
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
        }

        &::placeholder {
          color: #aaa;
        }
      }
    }
  }

  .alpha-demo-message {
    text-align: center;
    margin: 40px 0 30px;
    font-family: "Playfair Display", serif;
    font-style: italic;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .title-line {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      span {
        display: inline-block;
        animation: float 3s ease-in-out infinite;
      }

      .explore {
        font-size: 2.4rem;
        color: #333;
        font-weight: 600;
      }

      .our {
        font-size: 2rem;
        color: #555;
        animation-delay: 0.2s;
      }

      .highlight {
        font-size: 2.6rem;
        background: linear-gradient(45deg, #000, #333);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        animation-delay: 0.3s;
      }

      .separator {
        font-size: 2rem;
        color: #666;
        margin: 0 15px;
        animation-delay: 0.4s;
      }
    }

    .subtitle-line {
      .future {
        font-size: 2rem;
        color: #666;
        font-weight: 500;
        animation-delay: 0.5s;
        animation: float 3s ease-in-out infinite;
        display: inline-block;
      }
    }
  }

  .alpha-screenplay-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .alpha-screenplay-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 15px 20px;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;

      .alpha-screenplay-title {
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
        font-weight: 600;
        color: #333;
        width: 100%;
        text-align: center;
      }

      .alpha-screenplay-subtitle {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        font-weight: 600;
        color: #333;
        width: 100%;
        text-align: center;
      }

      .screenplay-buttons {
        display: flex;
        gap: 15px;
        justify-content: center;
        width: 100%;

        .transform-button {
          padding: 12px 24px;
          border: none;
          border-radius: 8px;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
          background-color: #000;
          color: white;
          transition: all 0.3s ease;
          font-family: "Montserrat", sans-serif;
          display: flex;
          align-items: center;
          gap: 8px;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #999;
            transform: none !important;

            &:hover {
              transform: none !important;
              box-shadow: none !important;
            }
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }

          &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .transform-icon,
          .transform-text,
          .transform-arrow {
            transition: all 0.3s ease;
          }
        }
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .alpha-screenplay {
      flex: 1;
      overflow-y: auto;
      padding: 20px;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 0.9rem;
      line-height: 1.6;

      .screenplay-grid {
        display: grid;
        grid-template-columns: minmax(80px, auto) 1fr;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        overflow: hidden;
        position: relative;

        .screenplay-row {
          display: contents;

          // Add border between rows
          .character-cell,
          .dialogue-cell {
            border-bottom: 1px solid #e5e7eb;
          }

          &:last-child {
            .character-cell,
            .dialogue-cell {
              border-bottom: none;
            }
          }
        }
        // Add styles for the insert dialogue button container
        .insert-dialogue-button-container {
          grid-column: 1 / -1; // Span both columns
          height: 20px; // Give it some height for better hover area
          position: relative;
          transition: all 0.2s ease;
          margin: -10px 0; // Negative margin to overlap with cell padding
          z-index: 2;

          .insert-dialogue-button {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: white;
            border: 1px dashed #ccc;
            color: #666;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            opacity: 0;
            transition: all 0.2s ease;
            z-index: 2;

            &:hover:not(:disabled) {
              background-color: #f3f4f6;
              border-color: #666;
              color: #333;
              transform: translate(-50%, -50%) scale(1.1);
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          &:hover .insert-dialogue-button {
            opacity: 1;
          }
        }
      }

      .character-cell {
        background-color: white;
        border-right: 1px solid #e5e7eb;
        padding: 10px;
        box-sizing: border-box;
        transition: background-color 0.2s ease;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;

        .character-input {
          width: 100%;
          text-align: center;
          border: none;
          background: none;
          font-weight: 600;
          padding: 4px;
          border-radius: 4px;
          transition: all 0.2s ease;

          &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.03);
          }

          &:focus {
            outline: none;
            background-color: rgba(0, 0, 0, 0.05);
          }

          &:disabled {
            color: inherit;
            cursor: default;
          }

          &::placeholder {
            color: #aaa;
            font-style: italic;
          }
        }
      }

      .dialogue-cell {
        background-color: white;
        box-sizing: border-box;
        transition: all 0.2s ease;
        display: flex;
        align-items: stretch;
        position: relative;
        gap: 0;
        border-bottom: 1px solid #e5e7eb;
        width: 100%; // Ensure it takes full width

        .dialogue-textarea {
          flex: 1;
          width: 100%;
          border: none;
          background: none;
          resize: none;
          padding: 10px;
          transition: all 0.2s ease;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          min-height: 24px;
          overflow: hidden;

          &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.03);
          }

          &:focus {
            outline: none;
            background-color: rgba(0, 0, 0, 0.05);
          }
        }

        .emotion-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid #e5e7eb;
          padding: 4px 8px;
          background-color: white;

          .custom-select {
            position: relative;
            width: 120px;

            .emotion-select {
              width: 100%;
              padding: 8px 12px;
              border-radius: 4px;
              border: 1px solid #ddd;
              background-color: white;
              font-family: inherit;
              font-size: 14px;
              font-weight: 600;
              color: #333;
              cursor: pointer;
              transition: all 0.2s ease;
              text-align: center;
              position: relative;

              // Add dropdown arrow
              &::after {
                content: "";
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #666;
                transition: transform 0.2s ease;
              }

              &:hover:not(:disabled) {
                border-color: #999;
                background-color: rgba(0, 0, 0, 0.02);
              }

              &:disabled {
                background-color: #f5f5f5;
                cursor: not-allowed;
                opacity: 0.7;
              }
            }

            .emotion-dropdown {
              position: absolute;
              top: calc(100% + 4px);
              left: 0;
              width: 100%;
              background: white;
              border: 1px solid #ddd;
              border-radius: 4px;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              opacity: 0;
              visibility: hidden;
              transform: translateY(-10px);
              transition: all 0.2s ease;
              z-index: 1000;

              .emotion-option {
                padding: 8px 12px;
                cursor: pointer;
                transition: all 0.2s ease;
                font-size: 14px;
                color: #333;

                &:hover {
                  background-color: #f3f4f6;
                }

                &.selected {
                  background-color: #f3f4f6;
                  font-weight: 600;
                }
              }
            }

            // Show dropdown when open
            &.open {
              .emotion-select::after {
                transform: translateY(-50%) rotate(180deg);
              }

              .emotion-dropdown {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
              }
            }
          }
        }

        .dialogue-play-button {
          height: 100%; // Take full height
          width: 44px; // Fixed width
          padding: 0;
          background: none;
          border: none;
          border-left: 1px solid #e5e7eb;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease;
          color: #000;
          flex-shrink: 0;

          &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.05);
          }

          &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
            color: #999;

            &:hover {
              transform: none;
              background: none;
            }
          }

          &.active {
            opacity: 1;

            svg {
              filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
            }
          }
        }

        .dialogue-regenerate-button {
          height: 100%;
          width: 44px;
          padding: 0;
          background: none;
          border: none;
          border-left: 1px solid #e5e7eb;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s ease;
          color: #000;
          flex-shrink: 0;

          &:hover:not(:disabled) {
            background-color: rgba(0, 0, 0, 0.05);
            transform: scale(1.1);
          }

          &:disabled {
            opacity: 0.3;
            cursor: not-allowed;
            color: #999;

            &:hover {
              transform: none;
              background: none;
            }
          }

          &.loading {
            cursor: not-allowed;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;

            .loading-spinner {
              width: 16px;
              height: 16px;
              border: 2px solid #f3f3f3;
              border-top: 2px solid #3498db;
              border-radius: 50%;
              animation: spin 1s linear infinite;
            }
          }
        }
      }

      .volume-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e5e7eb;
        border-left: 1px solid #e5e7eb;

        .volume-icon {
          font-size: 1.2rem;
          color: black;
          margin-left: 5px;
          margin-right: 5px;
          line-height: 1; // Adjust line-height to match the container
          vertical-align: middle;
        }

        .volume-slider-container {
          position: flex;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-left: 2px;

          .volume-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;

            .volume-icon-wrapper {
              display: flex;
              justify-content: center;
            }

            .volume-slider {
              top: 100%;
              display: block;
              transform-origin: center; /* Adjust the origin to align properly */
              height: 8px;
              width: 60px;
              padding-right: 5px;

              -webkit-appearance: none; // Remove default styling for WebKit browsers
              appearance: none; // Remove default styling for other browsers

              // Style the track
              &::-webkit-slider-runnable-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-moz-range-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-ms-track {
                width: 100%;
                height: 8px;
                background: transparent;
                border-color: transparent;
                color: transparent;
              }

              // Style the thumb
              &::-webkit-slider-thumb {
                -webkit-appearance: none; // Remove default styling for WebKit browsers
                appearance: none; // Remove default styling for other browsers
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
                margin-top: -2px;
              }

              &::-moz-range-thumb {
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
              }

              &::-ms-thumb {
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
              }
            }

            .volume-slider::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 12px;
              height: 12px;
              background-color: black;
              border-radius: 50%;
              cursor: pointer;
            }

            .volume-slider::-moz-range-thumb {
              width: 12px;
              height: 12px;
              background-color: black;
              border-radius: 50%;
              cursor: pointer;
            }
          }

          .volume-value {
            display: flex;
            justify-content: center;
            text-align: center;
          }

          &:has(input:disabled) {
            opacity: 0.5;
            cursor: not-allowed;

            .volume-icon {
              color: #999;
            }

            .volume-value {
              color: #999;
            }
          }

          .volume-slider:disabled {
            cursor: not-allowed;
          }
        }
      }

      .speed-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e5e7eb;
        border-left: 1px solid #e5e7eb;

        .speed-icon {
          font-size: 1.2rem;
          color: black;
          margin-left: 5px;
          margin-right: 5px;
          line-height: 1;
          vertical-align: middle;
        }

        .speed-slider-container {
          position: flex;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-left: 2px;

          .speed-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;

            .speed-icon-wrapper {
              display: flex;
              justify-content: center;
            }

            .speed-slider {
              top: 100%;
              display: block;
              transform-origin: center;
              height: 8px;
              width: 60px;
              padding-right: 5px;

              -webkit-appearance: none;
              appearance: none;

              // Style the track
              &::-webkit-slider-runnable-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-webkit-slider-runnable-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-moz-range-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-ms-track {
                width: 100%;
                height: 8px;
                background: transparent;
                border-color: transparent;
                color: transparent;
              }

              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
                margin-top: -2px;
              }

              &::-moz-range-thumb {
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
              }

              &::-ms-thumb {
                width: 16px;
                height: 16px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
              }
            }

            .speed-slider::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 12px;
              height: 12px;
              background-color: black;
              border-radius: 50%;
              cursor: pointer;
            }

            .speed-slider::-moz-range-thumb {
              width: 12px;
              height: 12px;
              background-color: black;
              border-radius: 50%;
              cursor: pointer;
            }
          }

          .speed-value {
            display: flex;
            justify-content: center;
            text-align: center;
          }

          &:has(input:disabled) {
            opacity: 0.5;
            cursor: not-allowed;

            .speed-icon {
              color: #999;
            }

            .speed-value {
              color: #999;
            }
          }

          .speed-slider:disabled {
            cursor: not-allowed;
          }
        }
      }

      .delete-cell {
        height: 100%; // Take full height
        width: 44px; // Fixed width
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-left: 1px solid #e5e7eb;
        padding: 0;

        .dialogue-delete {
          width: 24px;
          height: 24px;
          border: none;
          background: none;
          color: #999;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.2rem;
          padding: 0;
          transition: all 0.2s ease;

          &:hover {
            color: #ff4444;
            transform: scale(1.1);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            &:hover {
              color: #999;
              transform: none;
            }
          }
        }
      }
    }

    .add-dialogue-button {
      width: 100%;
      padding: 8px;
      margin-top: 10px;
      background: none;
      border: 1px dashed #ccc;
      border-radius: 4px;
      color: #666;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
        border-color: #999;
        color: #333;
      }

      .add-icon {
        font-size: 1.1rem;
      }
    }
  }

  .alpha-voice-assignment-view {
    height: 100%;
    display: flex;
    flex-direction: column;

    .alpha-voice-assignment-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      padding: 15px 20px;
      border-bottom: 2px solid #eee;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;

      .alpha-voice-assignment-title {
        margin: 0;
        padding: 0;
        font-size: 1.3rem;
        font-weight: 600;
        color: #333;
        width: 100%;
        text-align: center;
      }

      .confirm-voice-assignments-button {
        padding: 12px 24px;
        border: none;
        border-radius: 8px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        background-color: #000;
        color: white;
        transition: all 0.3s ease;
        font-family: "Montserrat", sans-serif;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 10px;

        .button-icon {
          font-size: 1.1rem;
        }

        .button-text {
          margin: 0;
        }

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
          background-color: #999;
          transform: none !important;

          &:hover {
            transform: none !important;
            box-shadow: none !important;
          }
        }

        &.loading {
          cursor: not-allowed;
          opacity: 0.8;
          display: flex;
          align-items: center;
          justify-content: center;

          .loading-spinner {
            width: 1rem;
            height: 1rem;
            border: 2px solid #f3f3f3;
            border-top: 2px solid #3498db;
            border-radius: 50%;
            animation: spin 1s linear infinite;
          }

          .button-text {
            margin-left: 8px;
          }

          .button-icon {
            display: none;
          }
        }
      }
    }

    .alpha-voice-assignment-content {
      flex: 1;
      overflow-y: auto;
      padding: 10px;

      .alpha-character-voice-card {
        background: white;
        border-radius: 12px;
        padding: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        }

        .alpha-character-info {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #eee;

          .alpha-character-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: #000;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            font-weight: 600;
          }

          .alpha-character-name {
            font-size: 1.2rem;
            margin: 0;
            color: #333;
            font-weight: 600;
          }
        }

        .alpha-assigned-voice {
          display: flex;
          align-items: flex-start;
          gap: 15px;

          .alpha-voice-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.1rem;
            color: white;
          }

          .alpha-voice-details {
            flex: 1;

            h4 {
              margin: 0 0 5px 0;
              font-size: 1.1rem;
              color: #333;
            }

            p {
              margin: 0 0 8px 0;
              font-size: 0.9rem;
              color: #666;
            }

            .alpha-voice-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;

              .alpha-voice-tag {
                background: #f5f5f5;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 0.8rem;
                color: #666;
              }
            }
          }

          .alpha-voice-actions {
            display: flex;
            gap: 12px;
            align-items: center;
            align-self: center;

            .alpha-preview-voice {
              background: none;
              border: none;
              cursor: pointer;
              color: #000;
              padding: 12px;
              width: 44px;
              height: 44px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s ease;
              margin-left: 10px;

              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
                transform: translateY(-2px);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              }

              &:active {
                transform: translateY(0);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
              }

              svg {
                font-size: 24px;
                transition: all 0.2s ease;
              }

              &.playing svg {
                color: #000;
                filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
              }
            }

            .alpha-change-voice {
              padding: 8px 16px;
              border: none;
              border-radius: 6px;
              font-size: 0.9rem;
              font-weight: 600;
              cursor: pointer;
              background-color: #000;
              color: white;
              transition: all 0.3s ease;
              font-family: "Montserrat", sans-serif;
              display: flex;
              align-items: center;
              gap: 6px;
              white-space: nowrap;

              &:hover {
                transform: translateY(-2px);
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
              }

              &:active {
                transform: translateY(0);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
              }

              &::after {
                content: "→";
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .alpha-voice-selection-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .alpha-modal-content {
      background: white;
      border-radius: 12px;
      width: 90%;
      max-width: 600px;
      max-height: 80vh;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .alpha-modal-header {
        padding: 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;

        h3 {
          margin: 0;
          font-size: 1.2rem;
          text-align: center;
          width: 100%;
        }

        .alpha-modal-close {
          position: absolute;
          right: 20px;
          top: 20px;
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          color: #666;
          padding: 0 8px;

          &:hover {
            color: #000;
          }
        }
      }

      .alpha-modal-search {
        padding: 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: center;
        align-items: center;

        .alpha-modal-search-input {
          width: 80%;
          max-width: 400px;
          padding: 12px;
          border: 2px solid #eee;
          border-radius: 8px;
          font-size: 1rem;

          &:focus {
            outline: none;
            border-color: #000;
          }
        }
      }

      .alpha-modal-voices {
        flex: 1;
        overflow-y: auto;
        padding: 20px;

        .alpha-modal-voice-item {
          display: flex;
          align-items: flex-start;
          gap: 15px;
          padding: 15px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;
          border: 1px solid transparent;

          &:hover {
            background: #f8f9fa;
          }

          &.selected {
            background: #f0f0f0;
            border: 1px solid #000;
          }

          .alpha-modal-voice-avatar {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 1.2rem;
            flex-shrink: 0;
          }

          .alpha-modal-voice-info {
            flex: 1;

            .alpha-modal-voice-name {
              font-size: 1.1rem;
              margin: 0 0 5px 0;
              color: #333;
              font-weight: 600;
            }

            .alpha-modal-voice-description {
              font-size: 0.9rem;
              color: #666;
              margin: 0 0 8px 0;
              line-height: 1.4;
            }

            .alpha-modal-voice-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 8px;

              .alpha-modal-voice-tag {
                background-color: #f5f5f5;
                color: #666;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 0.8rem;
              }
            }
          }

          .alpha-modal-preview {
            background: none;
            border: none;
            cursor: pointer;
            padding: 8px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s ease;
            color: #000;

            &:hover {
              background: rgba(0, 0, 0, 0.05);
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }

  .alpha-multitrack-view {
    height: 100%;

    // Add styles for PanelGroup
    [data-panel-group-direction="horizontal"] {
      height: 100%;
      background-color: white;
      margin: 0;
      padding: 0;
    }

    [data-panel-resize-handle-id] {
      width: 4px;
      background-color: #ddd;
      cursor: col-resize;
    }

    .alpha-multitrack-screenplay-panel {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif; // Match normal section font
      font-size: 0.9rem; // Match normal section font size
      line-height: 1.6; // Match normal section line height

      .alpha-screenplay-header {
        padding: 20px 20px;
        border-bottom: 2px solid #eee;
        background-color: white;
        position: sticky;
        top: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .alpha-screenplay-title {
          font-size: 1.3rem;
          font-weight: 600;
          margin: 0;
          color: #333;
          text-align: center;
        }

        .generate-bgm-button {
          padding: 10px 20px;
          border: none;
          border-radius: 8px;
          font-size: 0.9rem;
          font-weight: 600;
          cursor: pointer;
          background-color: #000;
          color: white;
          transition: all 0.3s ease;
          font-family: "Montserrat", sans-serif;
          display: flex;
          align-items: center;
          gap: 8px;

          .button-icon {
            font-size: 1.1rem;
          }

          .button-text {
            margin: 0;
          }

          .button-arrow {
            font-size: 1.1rem;
            transition: transform 0.3s ease;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }

          &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #999;
            transform: none !important;

            &:hover {
              transform: none !important;
              box-shadow: none !important;
            }
          }
        }

        .generate-sfx-button {
          padding: 10px 20px;
          border: none;
          border-radius: 8px;
          font-size: 0.9rem;
          font-weight: 600;
          cursor: pointer;
          background-color: #000;
          color: white;
          transition: all 0.3s ease;
          font-family: "Montserrat", sans-serif;
          display: flex;
          align-items: center;
          gap: 8px;

          .button-icon {
            font-size: 1.1rem;
          }

          .button-text {
            margin: 0;
          }

          .button-arrow {
            font-size: 1.1rem;
            transition: transform 0.3s ease;
          }

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }

          &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background-color: #999;
            transform: none !important;

            &:hover {
              transform: none !important;
              box-shadow: none !important;
            }
          }

          &.loading {
            cursor: not-allowed;
            opacity: 0.8;
            display: flex;
            align-items: center;
            justify-content: center;

            .button-text {
              margin-left: 8px;
            }

            .button-arrow {
              display: none;
            }
          }
        }
      }

      .alpha-screenplay-content {
        padding: 20px;
        flex: 1;
        overflow-y: auto;
        scroll-behavior: smooth;

        .dialogue-item {
          margin-bottom: 15px;
          padding: 10px;
          border-radius: 4px;
          border: 1px solid #ddd;
          text-align: left;

          .character {
            font-weight: bold;
            margin-right: 5px;
            color: #333;
          }

          .alpha-word {
            display: inline-block;
            cursor: pointer;
            padding: 2px;
            border-radius: 2px;
            transition: background-color 0.2s ease;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
            white-space: normal;
            word-break: keep-all;

            &:hover {
              background-color: rgba(0, 0, 0, 0.05) !important;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
            }

            &.current-word {
              background-color: #cdcccc !important;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
            }
          }
        }
      }
    }

    // Update existing multitrack container styles
    .alpha-multitrack-container {
      height: 100%;
      overflow-y: auto;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 0; // Remove padding from container
      width: 100%;

      .alpha-multitrack-title-section {
        flex-shrink: 0;
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 10;
        // Manually tuned padding that makes the border align with the
        // screenplay section
        padding: 28px 28px;
        border-bottom: 2px solid #eee;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .alpha-multitrack-title {
          font-size: 1.3rem;
          font-weight: 600;
          margin: 0;
          color: #333;
          text-align: center;
        }

        .alpha-multitrack-controls {
          display: flex;
          align-items: center;
          gap: 20px;

          .alpha-play-pause-button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #000;
            color: white;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

            &:hover {
              transform: translateY(-2px);
              background-color: #333;
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
            }

            &:active {
              transform: translateY(1px);
              box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
            }

            svg {
              font-size: 1.1rem;
            }

            &.playing {
              background-color: #333;
            }
          }

          .alpha-download-dropdown {
            position: relative;

            .alpha-download-button {
              padding: 10px 20px;
              border: none;
              border-radius: 8px;
              font-size: 0.9rem;
              font-weight: 600;
              cursor: pointer;
              background-color: #000;
              color: white;
              transition: all 0.3s ease;
              display: flex;
              align-items: center;
              gap: 8px;

              .button-icon {
                font-size: 1.1rem;
              }

              .button-text {
                margin: 0;
              }

              .dropdown-arrow {
                font-size: 0.8rem;
                transition: transform 0.2s ease;
              }

              &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
              }

              &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
                background-color: #999;
                transform: none !important;

                &:hover {
                  transform: none !important;
                  box-shadow: none !important;
                }
              }
            }

            .dropdown-content {
              display: none;
              position: absolute;
              top: 100%;
              right: 0;
              width: 160px;
              background-color: white;
              border: 1px solid #ddd;
              border-radius: 8px;
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
              margin-top: 8px;
              z-index: 10;

              button {
                width: 100%;
                padding: 12px 16px;
                border: none;
                background: none;
                text-align: center;
                cursor: pointer;
                font-size: 0.9rem;
                color: #333;
                transition: all 0.2s ease;
                font-family: "Montserrat", sans-serif;
                font-weight: 500;

                &:hover {
                  background-color: #f5f5f5;
                }

                &:first-child {
                  border-radius: 8px 8px 0 0;
                }

                &:last-child {
                  border-radius: 0 0 8px 8px;
                }
              }
            }

            &.active {
              .dropdown-content {
                display: block;
              }

              .alpha-download-button .dropdown-arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    .alpha-multitrack-wrapper {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      gap: 15px;
      position: relative;
      margin-top: 20px;
      padding-bottom: 40px;
      z-index: 1;
      height: fit-content;
      min-height: 100%;
      padding-left: 20px;
      padding-top: 10px;
      overflow-x: auto;

      .alpha-track-labels {
        display: flex;
        flex-direction: column;
        margin-right: 15px;
        min-width: 60px;
        padding: 0px;
        gap: 0px;
        font-size: 0px;
        line-height: 0px;
        margin-top: 10px;

        .alpha-audio-label {
          font-weight: bold;
          font-size: 0.8rem;
          text-align: center;
          height: 58px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0px;
          margin-bottom: -1px;
          white-space: pre;
          word-spacing: normal;
        }
        div[ref="alphaMultitrackContainerRef"] {
          height: 400px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .landing-page {
    .center-container {
      .content-wrapper {
        .headline {
          font-size: 2rem;
        }

        .paragraph {
          font-size: 1rem;
        }
      }
    }
  }
}

.alpha-screenplay-panel {
  .alpha-screenplay-content {
    .dialogue-item {
      .alpha-word {
        display: inline-block;
        cursor: pointer;
        padding: 2px;
        border-radius: 2px;
        transition: background-color 0.2s ease;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        color: inherit;
        white-space: normal;
        word-break: keep-all;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05) !important;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
        }

        &.current-word {
          background-color: #cdcccc !important;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !important;
        }
      }
    }
  }
}

// Add these styles for loading state
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Update button styles to handle loading state
.transform-button,
.voice-assignment-button,
.generate-voice-button {
  &.loading {
    cursor: not-allowed;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .button-text {
      margin-left: 8px;
    }

    .button-arrow,
    .transform-arrow {
      display: none;
    }
  }
}

.quota-error-message {
  color: #dc2626;
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.alpha-sfx-view {
  height: 100%;
  position: relative;
  overflow: hidden;

  // Left panel - Screenplay
  .alpha-sfx-screenplay-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e5e7eb;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif; // Match multitrack font
    font-size: 0.9rem;
    line-height: 1.6;

    .alpha-sfx-screenplay-header {
      padding: 20px;
      border-bottom: 1px solid #e5e7eb;
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
      display: flex; // Add flex display
      flex-direction: column; // Stack items vertically
      align-items: center; // Center items horizontally
      gap: 15px; // Add gap between title and button

      .alpha-sfx-screenplay-title {
        font-size: 1.3rem;
        font-weight: 600;
        margin: 0;
        color: #333;
        text-align: center;
        width: 100%; // Ensure title takes full width
        // we could make the screenplay section align with the sfx section
        // but it does not look good
        // padding-bottom: 30px;
      }

      .alpha-sfx-screenplay-subtitle {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
        color: #333;
        text-align: center;
        width: 100%; // Ensure title takes full width
        // we could make the screenplay section align with the sfx section
        // but it does not look good
        // padding-bottom: 30px;
      }
    }

    .alpha-sfx-screenplay-content {
      padding: 20px;
      flex: 1;
      overflow-y: auto;

      .screenplay-grid {
        display: grid;
        grid-template-columns: minmax(100px, auto) 1fr;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
        overflow: hidden;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;
        font-size: 0.9rem;
        line-height: 1.6;

        .screenplay-row {
          display: contents;

          // Add border between rows
          &:not(:last-child) {
            .character-cell,
            .dialogue-cell {
              border-bottom: 1px solid #e5e7eb;
            }
          }
        }
      }

      .character-cell {
        background-color: white; // Changed from #f8f9fa to white
        border-right: 1px solid #e5e7eb;
        padding: 10px;
        font-weight: 600;
        box-sizing: border-box;
        transition: background-color 0.2s ease;
        user-select: none;
        display: flex; // Add flex display
        align-items: center; // Center vertically
        justify-content: center; // Center horizontally
        min-height: 100%; // Ensure full height

        &:hover {
          background-color: rgba(0, 0, 0, 0.02); // Lighter hover effect
        }
      }

      .dialogue-cell {
        background-color: white;
        padding: 10px;
        box-sizing: border-box;
        transition: all 0.2s ease;
        cursor: text;
        display: block;
        overflow: visible;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: left;

        &:hover {
          background-color: rgba(0, 0, 0, 0.02); // Match character cell hover
        }

        // Preserve existing word and SFX styles
        .alpha-word {
          display: inline-block;
          padding: 2px;
          border-radius: 2px;
          transition: all 0.2s ease;
          cursor: pointer;
          user-select: text;

          &.has-sfx {
            background-color: rgba(59, 130, 246, 0.1);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }

          .sfx-icon {
            display: inline-block;
            margin-left: 4px;
            cursor: pointer;
            font-size: 0.9em;
            opacity: 0.7;
            transition: all 0.2s ease;

            &:hover {
              opacity: 1;
              transform: scale(1.1);
            }
          }
        }
      }
    }
  }
}

// Right panel - Sound Effects
.alpha-sfx-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .alpha-sfx-title-section {
    padding: 20px;
    border-bottom: 1px solid #e5e7eb;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column; // Change to column layout
    align-items: center; // Center items
    gap: 15px; // Add gap between title and button

    .alpha-sfx-title {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      color: #333;
      text-align: center;
      width: 100%; // Ensure title takes full width
    }

    .generate-sfx-button {
      padding: 12px 24px; // Match other button padding
      border: none;
      border-radius: 8px;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      background-color: #000; // Match other button colors
      color: white;
      font-family: "Montserrat", sans-serif;
      display: flex;
      align-items: center;
      gap: 8px;

      .button-icon {
        font-size: 1.1rem;
      }

      .button-text {
        margin: 0;
      }

      .button-arrow {
        font-size: 1.1rem;
        transition: transform 0.3s ease;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #999;
        transform: none !important;

        &:hover {
          transform: none !important;
          box-shadow: none !important;
        }
      }

      &.loading {
        cursor: not-allowed;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading-spinner {
          width: 1rem;
          height: 1rem;
          border: 2px solid #f3f3f3;
          border-top: 2px solid #3498db;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }

        .button-text {
          margin-left: 8px;
        }

        .button-arrow {
          display: none;
        }
      }
    }
  }

  .alpha-sfx-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;

    .sfx-description {
      background: white;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 15px;
      border: 1px solid #eee;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      &.selected {
        border-color: #3b82f6;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
        transform: translateY(-2px);
      }

      .sfx-description-header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid #eee;
        justify-content: space-between;

        .sfx-header-left {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .sfx-delete-button {
          background: none;
          border: 1px solid #dc2626;
          color: #dc2626;
          padding: 4px 8px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.875rem;
          transition: all 0.2s ease;

          &:hover:not(:disabled) {
            background-color: #dc2626;
            color: white;
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            border-color: #999;
            color: #999;

            &:hover {
              background: none;
            }
          }
        }
      }
    }
  }

  .sfx-description-text {
    margin: 0 0 12px 0;
    color: #444;
    line-height: 1.6;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 8px;

    .sfx-description-input {
      width: 100%;
      padding: 0px;
      min-height: 24px;
      border: none;
      border-radius: 4px;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      resize: none;
      overflow: hidden;
      background: transparent;
      transition: all 0.2s ease;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }

      &:focus {
        outline: none;
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  .sfx-sentence {
    margin: 0;
    font-style: italic;
    color: #666;
    font-size: 0.9rem;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
}

// Add styles for the button group
.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.sfx-popup {
  position: fixed;
  z-index: 1000;
  transform: translate(0, -50%);

  button {
    background-color: #000;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 0.85rem;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: all 0.2s ease;
    white-space: nowrap;
    font-family: "Montserrat", sans-serif;

    &:hover {
      transform: translateY(-1px);
      background-color: #222;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}

.sfx-description-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;

  .modal-content {
    background: white;
    padding: 24px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    h3 {
      margin: 0 0 16px 0;
      font-size: 1.2rem;
      color: #333;
    }

    .selected-text-container {
      margin-bottom: 20px;

      .selected-text {
        margin: 0;
        padding: 12px;
        background: #f5f5f5;
        border-radius: 6px;
        font-size: 0.9rem;
        line-height: 1.5;

        .selected-text-label {
          color: #666;
          font-size: 0.8rem;
          display: block;
          margin-bottom: 4px;
        }

        .selected-text-content {
          color: #000;
          font-weight: 500;
        }
      }
    }

    textarea {
      width: 100%;
      min-height: 120px;
      padding: 12px;
      border: 2px solid #eee;
      border-radius: 8px;
      font-family: inherit;
      font-size: 0.95rem;
      line-height: 1.5;
      margin-bottom: 20px;
      resize: vertical;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: #000;
      }
    }

    .modal-actions {
      display: flex;
      justify-content: flex-end;
      gap: 12px;

      button {
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 0.95rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &.cancel-button {
          background: none;
          border: 1px solid #ddd;
          color: #666;

          &:hover {
            background: #f5f5f5;
          }
        }

        &.confirm-button {
          background: #000;
          border: none;
          color: white;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

// Update the existing .alpha-word styles to handle selection
.alpha-word {
  position: relative;
  user-select: text !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;

  &.has-sfx {
    background-color: rgba(59, 130, 246, 0.1);
    border-radius: 3px;
  }

  .sfx-icon {
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;
    font-size: 0.9em;
    opacity: 0.7;
    transition: all 0.2s ease;
    position: relative;
    top: -1px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

.sfx-description-modal {
  .modal-content {
    .selected-text-container {
      margin-bottom: 20px;

      .selected-text,
      .context-text {
        margin: 0 0 8px 0;
        font-size: 0.9rem;
        line-height: 1.5;
      }

      .selected-text-label,
      .context-label {
        color: #666;
        font-size: 0.8rem;
        display: block;
        margin-bottom: 4px;
      }

      .selected-text-content {
        color: #000;
        font-weight: 500;
      }

      .context-content {
        color: #333;

        strong {
          color: #000;
          font-weight: 600;
          background-color: rgba(59, 130, 246, 0.1);
          padding: 2px 4px;
          border-radius: 3px;
        }
      }
    }
  }
}

.alpha-dialogue-add-between {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background: none;
  border: none;
  color: #666;
  // font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  // tune the margin to position the button in the middle of the dialogue item
  margin-top: -4px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  opacity: 0;

  &:hover {
    background-color: #fee2e2;
    color: #dc2626;
  }
}

.alpha-dialogue-item:hover + .alpha-dialogue-add-between,
.alpha-dialogue-add-between:hover {
  opacity: 1;
}

// Update input/textarea placeholder styles
.alpha-character,
.alpha-dialogue-text {
  &::placeholder {
    color: #aaa;
    font-style: italic;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    border-color: #999 !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
  }

  &:focus {
    background-color: white;
    border-color: #000 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

.projects-view {
  padding: 20px;
  height: 100%;
  overflow-y: auto;

  .projects-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }

  .projects-loading {
    text-align: center;
    padding: 2em;
    color: #666;
    font-size: 1.1rem;
    font-style: italic;
  }

  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 10px;

    .project-card {
      background: white;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }

      .project-title {
        margin: 0 0 10px 0;
        font-size: 1.1rem;
        color: #333;
        font-weight: 600;
        line-height: 1.4;
      }

      .project-date {
        font-size: 0.9rem;
        color: #666;
      }
    }

    .no-projects {
      grid-column: 1 / -1;
      text-align: center;
      padding: 40px;
      color: #666;
      font-style: italic;
    }
  }
}

.community-showcase {
  padding: 20px;
  height: 100%;
  overflow-y: auto;

  .community-title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }

  .community-loading {
    text-align: center;
    padding: 2em;
    color: #666;
    font-size: 1.1rem;
    font-style: italic;
  }

  .community-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 10px;

    .community-card {
      background: white;
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;
      display: flex;
      flex-direction: column;
      min-height: 150px; // Increased height to accommodate all elements
      position: relative;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      }

      .project-title {
        margin: 0 0 0 0;
        font-size: 1.2rem;
        color: #333;
        font-weight: 600;
        line-height: 1.4;
      }

      .project-info {
        padding-top: 12px;
        .project-details {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .project-author {
            font-style: italic;
            color: #666;
            font-size: 0.9rem;
            margin-bottom: 2px;
          }

          .project-date {
            color: #888;
            font-size: 0.85rem;
          }
        }

        .reaction-button {
          position: absolute;
          bottom: 16px;
          right: 16px;
          background: none;
          border: 1px solid #e5e7eb;
          padding: 8px 12px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          gap: 6px;
          color: #666;
          transition: all 0.2s ease;
          cursor: pointer;
          font-size: 0.9rem;

          &:hover {
            background-color: #f3f4f6;
            border-color: #d1d5db;
            transform: translateY(-2px);
          }

          &.active {
            background-color: #e3f2fd;
            border-color: #90caf9;
            color: #1976d2;
          }

          .reaction-count {
            font-weight: 500;
          }

          svg {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

// Add styles for the share button
.alpha-share-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #000;
  color: white;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;

  .button-icon {
    font-size: 1.1rem;
  }

  .button-text {
    margin: 0;
  }

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #999;
    transform: none !important;

    &:hover {
      transform: none !important;
      box-shadow: none !important;
    }
  }

  &.loading {
    cursor: not-allowed;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-spinner {
      width: 1rem;
      height: 1rem;
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .button-text {
      margin-left: 8px;
    }

    .button-icon {
      display: none;
    }
  }
}

// Add styles for read-only mode
.read-only {
  .menu-item {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.sfx-header-right {
  display: flex;
  align-items: center;
  gap: 10px;

  .volume-slider-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .volume-wrapper {
      display: flex;
      flex-direction: column; // Keep column layout for slider and multiplier
      align-items: center;
      gap: 4px;

      // Change to horizontal layout for icon and slider
      .volume-controls {
        display: flex;
        align-items: center;
        gap: 4px;

        .volume-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;

          .volume-icon {
            font-size: 1.1rem;
            color: #333;
          }
        }

        .volume-slider {
          width: 60px;
          height: 8px;
          -webkit-appearance: none;
          appearance: none;
          background: #ddd;
          border-radius: 4px;
          outline: none;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background-color: #333;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              transform: scale(1.1);
            }
          }

          &::-moz-range-thumb {
            width: 12px;
            height: 12px;
            background-color: #333;
            border-radius: 50%;
            cursor: pointer;
            transition: all 0.2s ease;
            border: none;

            &:hover {
              transform: scale(1.1);
            }
          }

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;

            &::-webkit-slider-thumb {
              cursor: not-allowed;
              &:hover {
                transform: none;
              }
            }

            &::-moz-range-thumb {
              cursor: not-allowed;
              &:hover {
                transform: none;
              }
            }
          }
        }
      }

      // Add multiplier value below slider
      .volume-multiplier {
        font-size: 0.7rem;
        color: #666;
        margin-top: -2px;
      }
    }
  }
}

// Control Buttons
.sfx-play-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  color: #000;

  &:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    color: #999;

    &:hover {
      transform: none;
      background: none;
    }
  }

  &.active {
    opacity: 1;

    svg {
      filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.2));
    }
  }
}

.sfx-regenerate-button {
  background: none;
  border: none;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  font-size: 1.2rem;
  padding: 0;

  &:hover:not(:disabled) {
    background-color: rgba(0, 0, 0, 0.05);
    transform: scale(1.1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &.active {
    opacity: 1;
  }

  &.loading {
    cursor: not-allowed;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-spinner {
      width: 16px;
      height: 16px;
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
  }
}

// Button Groups
.sfx-buttons {
  display: flex;
  gap: 10px;
}

// Apply Button
.apply-sfx-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  background-color: #000;
  color: white;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;

  .button-icon {
    font-size: 1.1rem;
  }

  .button-text {
    margin: 0;
  }

  .button-arrow {
    margin-left: 4px;
    font-size: 1.1rem;
  }

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #999;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }

  &.loading {
    cursor: not-allowed;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-spinner {
      width: 1rem;
      height: 1rem;
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .button-text {
      margin-left: 8px;
    }

    .button-icon {
      display: none;
    }
  }
}

.user-menu-container {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 1000;

  .user-initial-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #000;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: #333;
      transform: translateY(-2px);
    }
  }

  .user-menu {
    position: absolute;
    top: 50px;
    right: 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    min-width: 200px;

    .credits-section {
      padding: 20px;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0 0 15px;
        font-size: 1rem;
        color: #333;
      }

      .credits-info {
        margin-bottom: 15px;

        .credit-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          color: #666;
          font-size: 0.9rem;
        }
      }

      .upgrade-button {
        width: 100%;
        padding: 8px;
        background: #000;
        color: white;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: #333;
        }
      }
    }

    .menu-items {
      padding: 10px;

      button {
        width: 100%;
        padding: 8px;
        text-align: left;
        background: none;
        border: none;
        cursor: pointer;
        color: #666;
        transition: all 0.2s ease;
        border-radius: 6px;

        &:hover {
          background: #f5f5f5;
          color: #000;
        }
      }
    }
  }
}

.workspace-section {
  margin-top: auto;
  border-top: 1px solid #eee;
  cursor: pointer;
  margin-bottom: 0; // Remove bottom margin if any

  .workspace-info {
    display: flex;
    align-items: center;
    justify-content: center; // Center the content horizontally
    gap: 12px; // Slightly increase gap
    padding: 12px 15px; // Adjust padding
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    .user-initial {
      width: 32px; // Make circle larger
      height: 32px;
      border-radius: 50%;
      background: #000;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.9rem;
      font-weight: 500;
      flex-shrink: 0;
    }

    .workspace-title {
      font-size: 1rem; // Match menu item font size
      color: #666;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif; // Match menu font
      font-weight: 400; // Match menu font weight
    }
  }

  .user-menu {
    position: absolute;
    bottom: 0;
    left: calc(100% + 5px);
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    min-width: 240px;
    z-index: 1000;

    .credits-section {
      padding: 20px;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0 0 15px;
        font-size: 1rem;
        font-weight: 600;
        color: #333;
      }

      .credits-info {
        margin-bottom: 15px;
        background: #f8f8f8;
        padding: 12px;
        border-radius: 8px;

        .credit-row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          color: #666;
          font-size: 0.9rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .upgrade-button {
        width: 100%;
        padding: 10px;
        background: #000;
        color: white;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.2s ease;
        font-weight: 500;

        &:hover {
          background: #333;
          transform: translateY(-1px);
        }
      }
    }

    .menu-items {
      padding: 10px;

      button {
        width: 100%;
        padding: 10px;
        text-align: center; // Change from left to center
        background: none;
        border: none;
        cursor: pointer;
        color: #666;
        transition: all 0.2s ease;
        border-radius: 6px;
        font-size: 0.9rem;

        &:hover {
          background: #f5f5f5;
          color: #000;
        }
      }
    }
  }
}

.bgm-text-input-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bgm-text-input-header {
    padding: 15px 20px;
    background-color: white;

    .bgm-text-input-title {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
      font-weight: 600;
      color: #333;
      width: 100%;
      text-align: center;
    }

    .header-buttons {
      display: flex;
      gap: 10px;
      margin-top: 15px;
      justify-content: center;
      width: 100%;

      .transform-button,
      .apply-button {
        padding: 12px 24px;
        border: none;
        border-radius: 8px;
        background-color: #000;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: "Montserrat", sans-serif;
        letter-spacing: 0.3px;

        &:hover:not(:disabled) {
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
          background-color: #999;
        }
        .transform-icon {
          font-size: 1.1rem;
        }

        .transform-text {
          margin: 0;
        }

        .transform-arrow {
          font-size: 1.1rem;
          transition: transform 0.3s ease;
        }
      }

      // .apply-button {
      //   background-color: #666;
      // }
    }
  }
}

.bgm-outer-container {
  margin: 15px 20px; // Add horizontal margins
  border: 2px solid #eee;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  .bgm-controls-wrapper {
    padding: 15px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    background-color: white;

    .bgm-action-controls {
      display: flex;
      align-items: center;
      gap: 15px;

      .bgm-control-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px 12px; // Adjust padding for text buttons
        border-radius: 4px; // Match sound effects button style
        color: #666;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;
        font-size: 0.9rem; // Add for text buttons

        &:hover:not(:disabled) {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.delete {
          color: #dc3545;
          border: 1px solid #dc3545; // Add red border
          padding: 4px 8px;
          border-radius: 4px;
          cursor: pointer;
          font-size: 0.875rem;
          transition: all 0.2s ease;
        }

        &.play-button {
          // Special styles for play button
          padding: 4px; // Reduced from 8px
          width: 24px; // Reduced from 32px
          height: 24px; // Reduced from 32px
          border-radius: 50%;
          background-color: #000;
          color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover:not(:disabled) {
            background-color: #333;
            transform: scale(1.1); // Changed from translateY to scale
          }
        }

        // Add specific size for regenerate button
        &:not(.play-button):not(.delete) {
          // Target regenerate button
          width: 32px;
          height: 32px;
          padding: 0;
          font-size: 1.2rem;
        }

        // Add specific styles for regenerate button
        .loading-spinner {
          width: 16px;
          height: 16px;
          border: 2px solid #f3f3f3;
          border-top: 2px solid #3498db;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }

        .spinning {
          display: inline-block;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }

      .bgm-volume-control {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: -8px; // Move the entire control down a bit

        .volume-slider-container {
          position: flex;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-left: 2px;

          .volume-wrapper {
            display: flex;
            align-items: center;
            flex-direction: row;

            .volume-icon-wrapper {
              display: flex;
              justify-content: center;

              .volume-icon {
                font-size: 1.2rem;
                color: black;
                margin-left: 5px;
                margin-right: 5px;
                line-height: 1;
                vertical-align: middle;
              }
            }

            .volume-slider {
              top: 100%;
              display: block;
              transform-origin: center;
              height: 8px;
              width: 60px;
              padding-right: 5px;
              -webkit-appearance: none;
              appearance: none;

              &::-webkit-slider-runnable-track {
                width: 100%;
                height: 8px;
                background: #ddd;
                border-radius: 4px;
              }

              &::-webkit-slider-thumb {
                -webkit-appearance: none;
                width: 12px;
                height: 12px;
                background-color: black;
                border-radius: 50%;
                cursor: pointer;
                margin-top: -2px;
              }

              &:disabled {
                cursor: not-allowed;
              }
            }
          }

          .volume-value {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 0.9rem;
            color: #666;
          }

          &:has(input:disabled) {
            opacity: 0.5;
            cursor: not-allowed;

            .volume-icon {
              color: #999;
            }

            .volume-value {
              color: #999;
            }
          }
        }
      }
    }
  }

  .bgm-text-input-container {
    padding: 15px;
    position: relative;
    background-color: white;

    .bgm-text-input-area {
      width: 100%;
      min-height: 350px;
      border: none;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 0.9rem;
      line-height: 1.6;
      resize: none;
      color: #333;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #aaa;
      }
    }
  }
}

// Move character count outside and below
.bgm-word-count {
  margin: 10px 35px; // Match the padding/margins of input text section
  text-align: right;
  font-size: 0.9rem;
  color: #666;
}
