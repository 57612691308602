.verification-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
  font-family: "Montserrat", sans-serif;
}

.verification-container {
  width: 100%;
  max-width: 400px;
  padding: 40px 30px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;

  .product-title {
    width: 180px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.02);
    }
  }

  .verification-content {
    h2 {
      margin-bottom: 20px;
      color: #333;
      font-size: 1.5rem;
      font-weight: 600;
    }

    p {
      color: #666;
      margin-bottom: 15px;
      line-height: 1.5;
      font-size: 0.95rem;
    }

    .small-text {
      font-size: 0.85rem;
      color: #888;
      font-style: italic;
    }

    .login-button {
      margin-top: 25px;
      padding: 12px 30px;
      background-color: #666;
      color: white;
      border: none;
      border-radius: 8px;
      font-size: 0.9rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.3s ease;
      font-family: "Montserrat", sans-serif;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #555;
        transform: translateY(-1px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
