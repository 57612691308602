.blog-page {
  min-height: 100vh;
  background-color: #fff;

  .blog-header {
    background-color: white;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .product-title {
      height: 40px;
      cursor: pointer;
    }
  }

  .blog-content {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 2rem;

    .blog-main-title {
      font-size: 3.5rem;
      color: #000;
      margin-bottom: 1rem;
      text-align: center;
      font-weight: bold;
    }

    .blog-subtitle {
      font-size: 1.2rem;
      color: #666;
      text-align: center;
      max-width: 800px;
      margin: 0 auto 4rem;
      line-height: 1.6;
    }

    .blog-cards {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .blog-card {
        border: 1px solid #eee;
        border-radius: 12px;
        overflow: hidden;
        transition: all 0.3s ease;
        cursor: pointer;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        }

        .card-content {
          padding: 2rem;

          .card-meta {
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;

            span {
              color: #666;
              font-size: 0.9rem;
            }
          }

          h2 {
            font-size: 1.8rem;
            color: #000;
            margin-bottom: 1rem;
            font-weight: bold;
          }

          .card-description {
            color: #555;
            line-height: 1.6;
            margin-bottom: 1.5rem;
          }

          .card-author {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .author-avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .author-name {
              font-weight: 500;
              color: #333;
            }
          }
        }
      }
    }
  }
}
