.subscription-page {
  min-height: 100vh;
  padding: 40px 20px;
  background-color: #f5f5f5;
  font-family: "Montserrat", sans-serif;

  .product-title {
    position: absolute;
    top: 20px;
    left: 30px;
    width: 300px;
    max-height: 100px;
    cursor: pointer;
  }

  h1 {
    text-align: center;
    color: #333;
    margin: 80px 0 40px;
    font-size: 2.5rem;
  }

  .plans-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;

    .plan-card {
      background: white;
      border-radius: 12px;
      padding: 30px;
      text-align: center;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      h2 {
        color: #333;
        margin: 0 0 20px;
        font-size: 1.8rem;
      }

      .price {
        font-size: 2.5rem;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
      }

      .quota {
        color: #666;
        font-size: 1.1rem;
        margin-bottom: 20px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 2rem 0;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        li {
          margin: 0.5rem 0;
          color: #555;
          position: relative;
          padding-left: 25px;
          text-align: left;

          &:before {
            content: "✓";
            position: absolute;
            left: 0;
            color: #000;
          }

          &:last-child {
            margin-bottom: auto;
          }

          &:first-child {
            font-weight: normal;
            color: #555;
          }
        }
      }

      .subscribe-button {
        background: #000;
        color: white;
        border: none;
        padding: 12px 30px;
        border-radius: 8px;
        font-size: 1rem;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 100%;
        font-family: inherit;
        text-decoration: none;
        display: inline-block;
        box-sizing: border-box;

        &:hover {
          background: #333;
          transform: translateY(-2px);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .subscription-page {
    .plans-container {
      grid-template-columns: 1fr;
      padding: 10px;
    }
  }
}

.current-plan-button {
  background: #ccc;
  color: #666;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  font-family: inherit;
  cursor: not-allowed;
}
