.blog-post-page {
  min-height: 100vh;
  background-color: #fff;

  .blog-header {
    background-color: white;
    padding: 1rem 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .product-title {
      height: 40px;
      cursor: pointer;
    }
  }

  .blog-post-content {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;

    h1 {
      font-size: 2.5rem;
      color: #000;
      margin-bottom: 1.5rem;
      font-weight: bold;
    }

    .post-meta {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 3rem;
      color: #666;
      font-size: 0.9rem;

      .author {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        margin-left: auto;

        .author-avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        .author-name {
          font-weight: 500;
          color: #333;
        }
      }
    }

    section {
      margin-bottom: 3rem;

      h2 {
        font-size: 1.8rem;
        color: #000;
        margin-bottom: 1rem;
        font-weight: bold;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.8;
        color: #333;
        margin-bottom: 1rem;
      }

      .example {
        background-color: #f8f9fa;
        border-left: 4px solid #000;
        padding: 1.5rem;
        margin: 1.5rem 0;
        border-radius: 4px;

        p {
          font-family: monospace;
          margin: 0.5rem 0;
          color: #333;
          font-size: 1rem;
        }
      }
    }
  }
}
