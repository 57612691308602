.login-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  background-color: #f5f5f5;

  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px 30px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;

    .product-title {
      width: 220px;
      margin-bottom: 60px;
      cursor: pointer;
    }

    form {
      width: 100%;
      max-width: 340px;
      margin: 0 auto;

      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          margin-bottom: 8px;
          font-size: 0.85rem;
          color: #333;
          font-weight: 500;
          text-align: left;
          margin-left: 12px;
        }

        input {
          width: 100%;
          padding: 10px 12px;
          border: 1px solid #ddd;
          border-radius: 8px;
          font-size: 0.85rem;
          transition: all 0.3s ease;
          background-color: #f8f9fa;
          font-family: "Montserrat", sans-serif;
          box-sizing: border-box;

          &:focus {
            outline: none;
            border-color: #000;
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
            background-color: white;
          }

          &::placeholder {
            color: #aaa;
            font-size: 0.85rem;
          }
        }
      }

      button[type="submit"] {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        background-color: #666;
        color: white;
        border: none;
        border-radius: 8px;
        font-size: 0.85rem;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-top: 10px;
        font-family: "Montserrat", sans-serif;

        &:hover:not(:disabled) {
          background-color: #555;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }

      .signup-link {
        margin-top: 20px;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        color: #666;
        font-size: 0.85rem;

        span {
          font-weight: 400;
        }

        .signup-button {
          background: none;
          border: none;
          color: inherit;
          font-weight: 600;
          cursor: pointer;
          padding: 0;
          font-size: 0.85rem;
          font-family: "Montserrat", sans-serif;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .error-message {
      color: #dc3545;
      text-align: center;
      margin: 10px 0;
      font-size: 0.85rem;
    }

    .success-message {
      color: #28a745;
      text-align: center;
      margin: 10px 0;
      font-size: 0.85rem;
    }
  }
}

.password-input-container {
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    padding-right: 40px;
  }

  .password-toggle {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    color: #666;
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
